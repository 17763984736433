import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MoviePlayer,
  WeatherForecastProject,
  PhotograptherPortfolio,
  MovieSearchLink,
} from "./Projects/Projects";

export function Hobbies() {
  const hobbiesList = [
    "https://amantes30.github.io/icons/PNG/val-logo-small.png",
    "https://amantes30.github.io/icons/PNG/3dsmax.png",
    "https://amantes30.github.io/icons/PNG/blender.png",
    "https://amantes30.github.io/icons/PNG/twitch.png",
  ];
  const list = hobbiesList.map((iconUrl) => {
    let altName = iconUrl.split("/")[2].split(".")[0];
    return (
      <li key={uuidv4()}>
        <section style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <img style={{ height: "100%" }} src="/image/yosias G.png"></img>
          </div>
        </section>
        <section style={{}}>
          {" "}
          <a style={{ width: "100px" }} href="" key={uuidv4()}>
            <img
              style={{ maxWidth: "100px" }}
              className="skill-icons shadowed"
              alt={altName}
              src={iconUrl}
            ></img>
          </a>
        </section>
      </li>
    );
  });
  return (
    <div className="item hobbies-item">
      <ul className="hobbieslist">{list}</ul>
    </div>
  );
}

export function AboutMeItem({ username, profession, nationality }) {
  useEffect(() => {
    const intro = document.getElementById("intro-text");

    //   const skillList = [
    //     "Unity Game Developer",
    //     "Web Developer",
    //     "Master's Student",
    //     "Software Engineer",
    //   ];
    //   function printwithInterval(items, index){

    //     if(index < items.length){
    //       setTimeout(()=>{

    //         printwithInterval(items, index+1);
    //         console.log(items[index]);
    //         intro.innerText = items[index]

    //       }, 4000)

    //     }
    //     else{
    //       intro.style.animation = "unset"
    //     }

    //   }
    //  printwithInterval(skillList, 0)
  }, []);

  // fix to get name and profession
  return (
    <div className="item aboutme">
      <div className="pfp">
        <img
          style={{ objectFit: "cover", pointerEvents: "none" }}
          width={"100%"}
          height={"100%"}
          alt="pfp"
          src="/image/1024x1024.png"
        ></img>
      </div>
      <h1 className="gradient-colored-text" id="aboutmetext">
        {username}
      </h1>
      <h2 className="gradient-colored-text">{">" + nationality}</h2>
      <h2 id="intro-text" className="gradient-colored-text">
        {">"}Software Engineer
      </h2>
      <h2 className="gradient-colored-text">{">_"}</h2>
    </div>
  );
}
export function ProjectsItem() {
  const [viewProject, setView] = useState(4);
  let [myitem, setmyitem] = useState("");
  useEffect(() => {
    SelectItem(viewProject);
  }, [viewProject]);

  function SelectItem(num) {
    console.log(num);
    switch (num) {
      case 1:
        setmyitem(<MovieSearchLink />);
        break;
      case 2:
        setmyitem(<MovieSearchLink />);
        break;
      case 3:
        setmyitem(<WeatherForecastProject />);
        break;
      case 4:
        setmyitem(<PhotograptherPortfolio />);
        break;
    }
    setView(parseInt(num));
  }

  return (
    <div className="item projects-item">
      <h1 className="title gradient-colored-text">Projects</h1>
      {/* <select
        title="project-select"
        defaultValue={viewProject}
        className="project-dropdown-select"
        onChange={(e) => setView(parseInt(e.target.value))}
      >
        <option value={1}>Movie Search</option>
        <option value={2}>Movie Player</option>
        <option value={3}>7-Day weather forecast</option>
        <option value={4}>Photographer Portfolio</option>
      </select> */}
      <ul className="project-list">
        <li>
          <PhotograptherPortfolio />
        </li>
        <li>
          <WeatherForecastProject />
        </li>
        <li>
          <MovieSearchLink />
        </li>
      </ul>
      {/* {myitem} */}
    </div>
  );
}
export function ExperienceItem({ experiences }) {
  var list = [];

  experiences.map((experience) => {
    return list.push(
      <li key={uuidv4()}>
        <img src="https://git.amantes30.com/PNG/vswork-logo2024.png" />
        <div>
          <a
            style={{ color: "white", textDecoration: "none" }}
            href={"https://" + experience.link}
          >
            <h3>{experience.organization}</h3>
          </a>

          <p>{experience["job"]}</p>
          {experience.description}
        </div>
      </li>
    );
  });

  return (
    <div className="item experience-item">
      <h1 className="title gradient-colored-text">Internship</h1>
      <hr />
      <ul>{list}</ul>
    </div>
  );
}

export function SkillItem({ skills }) {
  var list = [];
  var list2 = [];
  var list3 = [];
  skills.forEach((skill) => {
    switch (skill.type) {
      case "lang":
        list.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      case "framework":
        list2.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      case "tool":
        list3.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      default:
        break;
    }
  });
  return (
    <>
      <div className="item skills">
        <div className="skills-animoji">
          <img src="/image/animoji.png"></img>
        </div>

        <section className="skill-section">
          <h2 className="gradient-colored-text">Programming languages</h2>
          <ul className="skillitemist scrollable">
            {list}
            {list}
          </ul>
        </section>

        <section className="skill-section">
          <h2 className="gradient-colored-text">Frameworks</h2>
          <ul className="skillitemist">{list2}</ul>
        </section>

        <section className="skill-section">
          <h2 className="gradient-colored-text">Tools</h2>
          <ul className="skillitemist scrollable">
            {list3}
            {list3}
          </ul>
        </section>

        {/* <p>3D modelling (3ds Max)</p> */}
      </div>
    </>
  );
}
