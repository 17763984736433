import axios from "axios";
import ItemList from "./components/ItemList.js";
import "./styles/style.css";
import "./styles/App.css";
import { useEffect, useState } from "react";
import User from "./models/user.ts";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

function App() {
  const [user, setUser] = useState(new User());
  const [loading, setLoadStatus] = useState(true);

  const getData = async () => {
    const params = {
      isAdmin: true,
    };

    // axios
    //   .get("https://api.amantes30.com/info", { params })
    //   .then((res) => {
    //     setLoadStatus(false);
    //     setUser(res.data[0]);
    //     console.log(res.data[0]);
    //   })
    //   .catch((err) => {
    //     console.log("Error " + err);
    //   });
    axios.get('/data.json').then((res)=>{
      setLoadStatus(false);
      setUser(res.data[0])

    }).catch((err) =>{
      console.log(err)
    })
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading && <LoadingScreen />}
      <Header />
      {user.idinfo ? <ItemList user={user} /> : ""}
      <Footer />
    </>
  );
}

export default App;
function LoadingScreen() {
  return (
    <div className="load-wrapper">
      <div className="spinner-grow text-primary" role="status">
        <span className="visually-hidden">Loading</span>
      </div>
    </div>
  );
}
