class User {
  idinfo : idinfo[];
  
  skills: Skill[];
  experiences: Experience[];
  projects: string;
  aboutme: string;
  contact: string[];
  hobbies: string[];
  constructor(
    idinfo:{},
    aboutme: string,
    experiences: [],
    skills: []
  ) {}
}
class idinfo {
  constructor(
    firstname: string,
    lastname: string,
    profession: string,
    profileimgurl: string
  ) {}
}
class Experience {
  constructor(
    public organization: string,
    public job: string,
    public description: string
  ) {}
}
class Skill {
  constructor(public text: string, public icon: string, public type: string) {}
}
export default User;
