import { useEffect } from "react";

function Header() {
  useEffect(() => {
    const nameCard = document.querySelector(".name-card");
    nameCard.addEventListener("click", () => {
      nameCard.classList.toggle("open");
    });
    const navBaropener = document.querySelector(".logo-img");
    const listofNav = document.querySelector(".options-list");
    navBaropener.addEventListener("click", () => {
      listofNav.classList.toggle("off");
    });
  }, []);
  return (
    <header>
      <div className="name-card">
        <div className="profile-pic">
          <img src="/image/1024x1024.png" alt="" />
        </div>
        <div className="info">
          <p>Amanuel</p>
          <p>Age: 24</p>
        </div>
        
      </div>
      <div className="my-dropdown-menu">
        <div className="logo-img opener">
          <img src="https://git.amantes30.com/SVG/logo(new).svg" alt="" />
          <ul className="options-list off">
            <p>Very Dangerous links :)</p>
            <hr/>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#projects">Projects</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
