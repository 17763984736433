import { useEffect } from "react";
import { AboutMeItem, ExperienceItem, ProjectsItem, SkillItem } from "./Item";

function ItemList({ user }) {
  // useEffect(() => {
  //   const items = document.querySelectorAll(".item");
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         entry.target.classList.toggle("show", entry.isIntersecting);
  //         console.log(entry.target.classList);
  //         if (!entry.target.classList.contains("aboutme")) {
  //           document.querySelector(".name-card").classList.add("open");
  //         } else {
  //           document.querySelector(".name-card").classList.remove("open");
  //         }
  //         //observer.unobserve(entry.target)
  //       });
  //     },
  //     {
  //       root: document.querySelector(".wrapper"),
  //       rootMargin: "-200px",
  //     }
  //   );
  //   items.forEach((item) => {
  //     observer.observe(item);
  //   });
  // }, []);
  return (
    <>
      <div className="wrapper">
        <AboutMeItem
          username={user.idinfo[0].firstname}
          profession={user.aboutme}
          nationality={user.idinfo[0].nationality}
        />
        <SkillItem skills={user.skills} />
        <ExperienceItem experiences={user.experiences} />
        <ProjectsItem projects={user.projects} />
      </div>
    </>
  );
}
export default ItemList;
